export const legacyTranslate = {
  ad_status: {
    200: '200: Session OK',
    400: '400: Bad Request (URL format)',
    401: '401: Unauthorized',
    402: '402: Payment Required',
    403: '403: Forbidden',
    404: '404: Content Not Found'
  },

  ad_skipped: {
    0: 'No',
    1: 'Yes'
  },

  ad_type: {
    0: 'Ad',
    1: 'Fallback',
    2: 'Slate'
  },

  blacklisted: {
    no: 'No',
    yes: 'Yes'
  },

  region : {
    ARA: 'Auvergne-Rhône-Alpes',
    BFC: 'Bourgogne-Franche-Comté',
    BRE: 'Bretagne',
    COR: 'Corse',
    CVL: 'Centre-Val de Loire',
    GES: 'Grand Est',
    GUA: 'Guadeloupe',
    HDF: 'Hauts-de-France',
    IDF: 'Ile-de-France',
    MAY: 'Mayotte',
    NAQ: 'Nouvelle-Aquitaine',
    NOR: 'Normandie',
    OCC: 'Occitanie',
    PAC: 'Provence-Alpes-Cote-d\'Azur',
    PDL: 'Pays de La Loire'
  },

  country: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barb.',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herz.',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'St. Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CD: 'Congo (Kinshasa)',
    CF: 'Central African Rep.',
    CG: 'Congo (Brazzaville)',
    CH: 'Switzerland',
    CI: 'Ivory Coast',
    CK: 'Cook Is.',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curacao',
    CY: 'Cyprus',
    CZ: 'Czech Rep.',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Rep.',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Is.',
    FM: 'Micronesia',
    FO: 'Faroe Is.',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GG: 'Guernsey',
    GH: 'Ghana',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GQ: 'Eq. Guinea',
    GR: 'Greece',
    GS: 'S. Geo. and S. Sandw. Is.',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard I. and McDonald Is.',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'Br. Indian Ocean Ter.',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'St. Kitts and Nevis',
    KP: 'N. Korea',
    KR: 'S. Korea',
    KW: 'Kuwait',
    KY: 'Cayman Is.',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'St. Martin',
    MG: 'Madagascar',
    MH: 'Marshall Is.',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macau',
    MP: 'N. Mariana Is.',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    'NA.': 'Not Available',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Fr. Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'St. Pierre and Miquelon',
    PN: 'Pitcairn Is.',
    PR: 'Puerto Rico',
    PS: 'West Bank',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Is.',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'S. Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Is.',
    TD: 'Chad',
    TF: 'Fr. S. and Antarctic Lands',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TL: 'East Timor',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican',
    VC: 'St. Vin. and Gren.',
    VE: 'Venezuela',
    VG: 'British Virgin Is.',
    VI: 'U.S. Virgin Is.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    _0: 'Aland',
    _1: 'N. Cyprus',
    _2: 'Indian Ocean Ter.',
    _3: '""',
    _4: 'Kosovo',
    _5: 'W. Sahara',
    _6: 'Somaliland',
    A1: 'Anonymous Proxy',
    AN: 'Anonymous Proxy',
    GP: 'Guadeloupe',
    EU: 'Europe',
    AP: 'Asia Pacific',
    A2: 'Satellite Provider'
  },

  error_types: {
    fail_delay:    'Delay',
    fail_load:     'Server load',
    fail_overhead: 'Overhead',
    fail_syntax:   'Syntax error',
    fail_unknown:  'Unknown frame'
  },

  filters_category: {
    content_filters:    'Content filters',
    equipment_filters:  'Equipment filters',
    network_filters:    'Network filters',
    indicators_filters: 'Indicators filters',
    session_filters:    'Session filters',
    additional_filters: 'Additional filters'
  },

  metrics: {
    none:                       'None',

    ucdn_session:               'Session Requests',
    ucdn_succeed_redirect:      'Succeed Requests',
    ucdn_failed_redirect:       'Failed Requests',
    ucdn_request_with_no_rules: 'Request with no rules',
    ucdn_number_rules_checked:  'Number Rules checked',
    ucdn_unique_visitor:        'Unique Visitor',
    ucdn_unique_content:        'Unique Content',
    ucdn_none:                  'None',

    cdn_sessions:            'Processed Sessions',
    cdn_error_sessions:      'Error Sessions',
    cdn_asset_duration:      'Asset Duration',
    cdn_delivered_volume:    'Delivered Volume',
    cdn_mean_bitrate:        'Bitrate',
    cdn_session_duration:    'Session Duration',
    cdn_response_time:       'Response Time',
    cdn_progress:            'Completion',
    cdn_concurrent_sessions: 'Concurrent Sessions',
    cdn_concurrent_bitrate:  'Concurrent Bitrate',
    cdn_none:                'None',

    nano_sessions:                'Processed Sessions',
    nano_concurrent_sessions:     'Concurrent Sessions',
    nano_concurrent_bitrate:      'Concurrent Bitrate',
    nano_retransmission_rate:     'Retransmission Rate',
    nano_retransmission_overhead: 'Retransmission Overhead',
    nano_multicast_unicast_ratio: 'Multicast/unicast Ratio',
    nano_none:                    'None',

    pl_sessions:             'Processed Sessions',
    pl_error_sessions:       'Error Sessions',
    pl_session_duration:     'Session Duration',
    pl_delivered_volume:     'Delivered Volume',
    pl_unique_visitor:       'Unique Users',
    pl_unique_content:       'Unique Contents',
    pl_startup_time:         'Startup Time',
    pl_cnt_stall:            'Stall Count',
    pl_total_stall_duration: 'Stall Duration',
    pl_cnt_layer_switch:     'Layer Switch',
    pl_vx:                   'Viewer Experience',
    pl_redirection_time:     'Redirection Time',
    pl_stall_rate:           'Stall Rate (stall duration per session duration)',
    pl_session_no_image:     'Session Exit Before Video Start',
    pl_mean_bitrate:         'Bitrate',
    pl_concurrent_sessions:  'Concurrent Sessions',
    pl_concurrent_bitrate:   'Concurrent Bitrate',
    pl_none:                 'None',

    ad_ad_request:               'Ads manipulation Request',
    ad_ad_success:               'Ads manipulation Success',
    ad_ad_error:                 'Ads manipulation Error',
    ad_impression:               'Ads Impression',
    ad_impression_not_played:    'Not Started Ads',
    ad_skippable_ad:             'Skippable Ads',
    ad_skipped_ad:               'Skipped Ads',
    ad_ad_decision_reponse_time: 'Decision Time',
    ad_skip_rate:                'Skip Rate',
    ad_ad_vx:                    'Ad Viewer Experience',
    ad_ad_server_time:           'Ad Server Time',
    ad_origin_time:              'Origin Time',
    ad_ad_mean_bitrate:          'Bitrate',
    ad_ad_stalls_count:          'Stall Count',
    ad_ad_stalls_duration:       'Stall Duration',
    ad_ad_layer_switches_number: 'Layer Switch',
    ad_ad_played_time:           'Played Time',
    ad_ad_completion:            'Completion',
    ad_concurrent_sessions:      'Concurrent Sessions',
    ad_sessions:                 'Processed Sessions with Ad',
    ad_vast_status_valid:        'VAST Status Valid',
    ad_vast_status_invalid:      'VAST Status Invalid',
    ad_count:                    'Ad Unit Count',
    ad_ad_none:                  'None'
  },

  metrics_hybrid: {
    none:                       'None',

    ucdn_session:               '(CDN SELECTOR) Session Requests',
    ucdn_succeed_redirect:      '(CDN SELECTOR) Succeed Requests',
    ucdn_failed_redirect:       '(CDN SELECTOR) Failed Requests',
    ucdn_request_with_no_rules: '(CDN SELECTOR) Request with no rules',
    ucdn_number_rules_checked:  '(CDN SELECTOR) Number Rules checked',
    ucdn_unique_visitor:        '(CDN SELECTOR) Unique Visitor',
    ucdn_unique_content:        '(CDN SELECTOR) Unique Content',
    ucdn_none:                  'None',

    cdn_sessions:            '(BROADPEAK CDN) Processed Sessions',
    cdn_error_sessions:      '(BROADPEAK CDN) Error Sessions',
    cdn_asset_duration:      '(BROADPEAK CDN) Asset Duration',
    cdn_delivered_volume:    '(BROADPEAK CDN) Delivered Volume',
    cdn_mean_bitrate:        '(BROADPEAK CDN) Bitrate',
    cdn_session_duration:    '(BROADPEAK CDN) Session Duration',
    cdn_response_time:       '(BROADPEAK CDN) Response Time',
    cdn_progress:            '(BROADPEAK CDN) Completion',
    cdn_concurrent_sessions: '(BROADPEAK CDN) Concurrent Sessions',
    cdn_concurrent_bitrate:  '(BROADPEAK CDN) Concurrent Bitrate',
    cdn_none:                'None',

    nano_sessions:                '(NANO CDN) Processed Sessions',
    nano_concurrent_sessions:     '(NANO CDN) Concurrent Sessions',
    nano_concurrent_bitrate:      '(NANO CDN) Concurrent Bitrate',
    nano_retransmission_rate:     '(NANO CDN) Retransmission Rate',
    nano_retransmission_overhead: '(NANO CDN) Retransmission Overhead',
    nano_multicast_unicast_ratio: '(NANO CDN) Multicast/unicast Ratio',
    nano_none:                    'None',

    pl_sessions:             '(PLAYERS) Processed Sessions',
    pl_error_sessions:       '(PLAYERS) Error Sessions',
    pl_session_duration:     '(PLAYERS) Session Duration',
    pl_delivered_volume:     '(PLAYERS) Delivered Volume',
    pl_unique_visitor:       '(PLAYERS) Unique Users',
    pl_unique_content:       '(PLAYERS) Unique Contents',
    pl_startup_time:         '(PLAYERS) Startup Time',
    pl_cnt_stall:            '(PLAYERS) Stall Count',
    pl_total_stall_duration: '(PLAYERS) Stall Duration',
    pl_cnt_layer_switch:     '(PLAYERS) Layer Switch',
    pl_vx:                   '(PLAYERS) Viewer Experience',
    pl_redirection_time:     '(PLAYERS) Redirection Time',
    pl_stall_rate:           '(PLAYERS) Stall Rate (stall duration per session duration)',
    pl_session_no_image:     '(PLAYERS) Session without a first image',
    pl_mean_bitrate:         '(PLAYERS) Bitrate',
    pl_concurrent_sessions:  '(PLAYERS) Concurrent Sessions',
    pl_concurrent_bitrate:   '(PLAYERS) Concurrent Bitrate',
    pl_none:                 'None',

    ad_ad_request:               '(AD INSERTION) Ads Request',
    ad_ad_success:               '(AD INSERTION) Ads Success',
    ad_ad_error:                 '(AD INSERTION) Ads Error',
    ad_skippable_ad:             '(AD INSERTION) Skippable Ads',
    ad_skipped_ad:               '(AD INSERTION) Skipped Ads',
    ad_ad_decision_reponse_time: '(AD INSERTION) Decision Time',
    ad_skip_rate:                '(AD INSERTION) Skip Rate',
    ad_ad_vx:                    '(AD INSERTION) Ad Viewer Experience',
    ad_ad_server_time:           '(AD INSERTION) Ad Server Time',
    ad_origin_time:              '(AD INSERTION) Origin Time',
    ad_ad_mean_bitrate:          '(AD INSERTION) Bitrate',
    ad_ad_stalls_count:          '(AD INSERTION) Stall Count',
    ad_ad_stalls_duration:       '(AD INSERTION) Stall Duration',
    ad_ad_layer_switches_number: '(AD INSERTION) Layer Switch',
    ad_ad_played_time:           '(AD INSERTION) Played Time',
    ad_ad_completion:            '(AD INSERTION) Completion',
    ad_impression:               '(AD INSERTION) Ads Impression',
    ad_impression_not_played:    '(AD INSERTION) Ads Not Played',
    ad_concurrent_sessions:      '(AD INSERTION) Concurrent Sessions',
    ad_sessions:                 '(AD INSERTION) Processed Sessions with Ad',
    ad_count:                    '(AD INSERTION) Ad Unit Count',
    ad_vast_status_valid:        '(AD INSERTION) VAST Status Valid',
    ad_vast_status_invalid:      '(AD INSERTION) VAST Status Invalid',
    ad_ad_none:                  'None'
  },

  filters: {
    account_id: 'Account Id',
    ad_id: 'Ad Id',
    ad_skipped: 'Ad Skipped',
    ad_server_id: 'Ad Server Name',
    ad_slot_interval: 'Ad Slot (Live only)',
    ad_status: 'Ad Status Code',
    ad_type: 'Ad Type',
    ab_groupname: 'Group Name',
    ab_testname: 'Test Name',
    asset_id: 'Asset Id',
    attachment_pop: 'Attachment Pop',
    blacklisted: 'Blacklist',
    browser_type: 'User Agent',
    cdn: 'CDN Profile',
    cdn_provider: 'CDN Provider',
    channel_network_id: 'Channel Network Id',
    channel_networks: 'Channel Networks',
    city: 'City',
    client_id: 'Client ID',
    client_ip: 'Client IP',
    content_name: 'Content Name',
    content_type: 'Content Type',
    country: 'Country',
    customer_id: 'Customer Id',
    dma: 'DMA',
    delivery_pop: 'Delivery Pop',
    device_os: 'Device OS',
    device_type: 'Device Type',
    device_version: 'OS Version',
    edge_node: 'Edge Node',
    environment: 'Environment',
    error_code: 'Error Code',
    event_id: 'Event Id',
    event_name: 'Event Name',
    ipversion: 'IP Version',
    is_ad_skippable: 'Ad Skippable',
    isp: 'ISP',
    location: 'Location',
    mac: 'MAC Address',
    media_type: 'Media Type',
    multicast: 'Multicast',
    multicast_address: 'Multicast',
    nano_status: 'nanoCDN Status',
    nano_device:'nanoCDN Device',
    nano_os:'nanoCDN OS',
    network_used_list: 'Network Type',
    network_subtype_used_list: 'Network Subtype',
    smartlib_version: 'SmartLib Version',
    network_connection: 'Network Access',
    network_type: 'Delivery Network',
    nsp: 'ISP',
    os_family: 'Device OS',
    perso_status: 'Personalization',
    platform_id: 'Platform Id',
    played_time: 'Played Time (s)',
    player_name: 'Player',
    player_version: 'Player Version',
    player_error_code: 'Player Code',
    protocol: 'Protocol',
    provider_name: 'Provider Name',
    purchase_id: 'Purchase Id',
    reseller_name: 'Reseller Name',
    region: 'Region',
    rule_id: 'Rule Id',
    rule_name: 'Rule Name',
    series_id: 'Serie Id',
    series_name: 'Serie Name',
    server_type: 'Server Type',
    servername: 'Server Name',
    service_id: 'Service Id',
    service_name: 'Service Name',
    service_type: 'Service Type',
    session_id: 'Session id',
    status_code: 'Status Code',
    storage_type: 'Storage Type',
    success_status: 'Status Code',
    success: 'Success',
    terminal_id: 'Terminal Id',
    timeline_available: 'Timeline',
    tos: 'QoS IP DSCP',
    user_agent: 'User Agent',
    usergroup: 'User Group',
    user_id: 'User Id',
    uuid: 'User Id',
    vast_status: 'VAST Status Code',
    videoserver: 'Video Server',
    pop: 'Delivery Pop',
    ufo_hostname: 'uCDN Name',
    zipcode: 'Zipcode',
    zone_id: 'Zone Id',
    // numerical filters
    ad_completion: 'Ad: Completion (0%, 25%, 50%, 75%, 100%)',
    ad_layer_switches_number: 'Ad: Number of layer switches',
    ad_mean_bitrate: 'Ad: Average bitrate (bps)',
    ad_played_time: 'Ad: duration (s)',
    ad_stalls_duration: 'Ad: Freezes duration',
    ad_stalls_number: 'Ad: No of freezes',
    ad_time: 'Get manifest time (Ad server)',
    ad_freeze_percentage_rating: 'Ad: Feeze percentage grade (0-10)',
    ad_freeze_frequency_rating: 'Ad: Freeze frequency grade (0-10)',
    ad_layer_rating: 'Ad: Bitrate grade (0-10)',
    ad_layer_switches_rating: 'Ad: Layer switches grade (0-10)',
    ad_vx: 'Ad: Viewer experience (0-10)',
    asset_duration: 'Asset duration (s)',
    cnt_layer_switch: 'No of layer switches',
    cnt_rebuffering: 'No of rebufferings',
    cnt_stall: 'No of freezes',
    freeze_frequency_rating: 'Freeze frequency grade (0-10)',
    freeze_percentage_rating: 'Freeze percentage grade (0-10)',
    layer_rating: 'Bitrate grade (0-10)',
    layer_switches_rating: 'Layer switches grade (0-10)',
    max_bitrate: 'Max bitrate (kbps)',
    max_rebuffering_time: 'Max rebuffering time (ms)',
    max_stall_time: 'Max freeze time (ms)',
    mean_bitrate: 'Average bitrate (kbps)',
    nb_customer_requests: 'No of requests',
    origin_time: 'Get manifest time (Origin)',
    progress: 'Completion (0-100)',
    session_duration: 'Session duration (s)',
    startup_rating: 'Startup time grade (0-10)',
    startup_time: 'Startup time (ms)',
    total_rebuffering_time: 'Total rebuffering time (ms)',
    total_stall_time: 'Total freeze time (ms)',
    vast_time: 'Ad decision response time',
    vx: 'Viewer experience (0-10)'
  },

  functions: {
    avg:     'Average',
    max:     'Max',
    min:     'Min',
    percent: 'Percentil 90',
    sum:     'Sum',
    count:   'Count',
    unique:  'Unique'
  },

  fields: {
    account_id: 'Account Id',
    ab_groupname: 'Group Name',
    ab_testname: 'Test Name',
    appversion: 'App Version',
    asset_id: 'Asset Id',
    attachment_pop: 'Attachment Pop',
    blacklisted: 'IP blacklisted',
    browser_type: 'User Agent',
    cdn: 'CDN Profile',
    cdn_provider: 'CDN Provider',
    channel_network_id: 'Channel Network Id',
    channel_networks: 'Channel Networks',
    city: 'City',
    client_id: 'Client ID',
    client_ip: 'Client IP',
    client_port: 'Client port',
    cmd:'Command',
    commercial_name: 'Commercial name',
    content_name: 'Content Name',
    content_type: 'Content Type',
    content_url: 'Content URL',
    country: 'Country',
    customer_id: 'Customer Id',
    dma: 'DMA',
    date: 'Start Of Session',
    date_stop: 'End Of Session',
    delivery_pop: 'Delivery Pop',
    device_os: 'Device OS',
    device_type: 'Device Type',
    device_version: 'OS Version',
    edge_node: 'Edge Node',
    environment: 'Environment',
    error_code: 'Error Code',
    event_id: 'Event Id',
    event_name: 'Event Name',
    freeze_percentage: 'Freeze Percentage',
    https: 'HTTP/HTTPS',
    interface :'Interface',
    ip_port:'IP Port',
    ipversion: 'IP Version',
    is_ad_skippable: 'Ad Skippable',
    isp: 'ISP',
    location: 'Location',
    mac: 'MAC Address',
    media_library: 'Media library',
    media_type: 'Media Type',
    multicast: 'Multicast',
    multicast_address: 'Multicast',
    nano_status: 'nanoCDN Status',
    nano_device:'nanoCDN Device',
    nano_os:'nanoCDN OS',
    network_used_list: 'Network Type',
    network_subtype_used_list: 'Network Subtype',
    smartlib_version: 'SmartLib Version',
    network_connection: 'Network Access',
    network_type: 'Delivery Network',
    nsp: 'ISP',
    os_family: 'Device OS',
    perso_status: 'Personalization',
    platform_id: 'Platform Id',
    played_time: 'Played Time',
    player_name: 'Player',
    player_version: 'Player Version',
    player_error_code: 'Player Code',
    protocol: 'Protocol',
    provider_name: 'Provider Name',
    purchase_id: 'Purchase Id',
    region:'Region',
    reseller_name: 'Reseller Name',
    rule_id: 'Rule Id',
    redirect_url: 'Redirect URL',
    referrer_domain:'Referrer domain',
    rule_name: 'Rule Name',
    series_id: 'Serie Id',
    series_name: 'Serie Name',
    server_type: 'Server Type',
    servername: 'Server name',
    server_version: 'Server version',
    service_id: 'Service id',
    service_name: 'Service name',
    service_type: 'Service type',
    session_id: 'Session id',
    severity: 'Session status',
    status_code: 'Status code',
    storage_type: 'Storage type',
    success_status: 'Status code',
    success: 'Success',
    terminal_id: 'Terminal id',
    timeline:'Timeline',
    timeline_available: 'Timeline available',
    tos: 'QoS IP DSCP',
    user_agent: 'User agent',
    usergroup: 'User group',
    user_id: 'User id',
    uuid: 'User id',
    vast_status: 'VAST status code',
    videoserver: 'Video server',
    pop: 'POP name',
    ufo_hostname: 'uCDN name',
    zipcode: 'Zip code',
    zone_id: 'Zone id',
    // numerical filters
    asset_duration: 'Asset duration',
    bwm_time: 'Bandwith manager time',
    cached_bytes: 'Cached Bytes from multicast',
    cached_unicast_bytes: 'Cached Bytes from unicast',
    chunk_duration: 'Chunck Duration',
    client_setup_time: 'Setup time',
    cnt_layer_switch: 'No of layer switches',
    cnt_rebuffering: 'No of rebufferings',
    cnt_stall: 'No of freezes',
    desc_time: 'Description time',
    different_layers: 'Nb Layers used',
    filesize: 'File size',
    freeze_frequency_rating: 'Freeze frequency grade ',
    freeze_percentage_rating: 'Freeze percentage grade',
    geoloc_time:'Geolocation time',
    layer_rating: 'Bitrate grade',
    layer_switches_rating: 'Layer switches grade',
    max_bitrate: 'Max bitrate',
    max_rebuffering_time: 'Max rebuffering time',
    max_req_on_packet: 'Max request on packet',
    max_stall_time: 'Max freeze time',
    mean_bitrate: 'Average bitrate',
    min_bitrate: 'Min bitrate',
    min_req_on_packet: 'Min request on packet',
    missed_bytes: 'Missed Bytes',
    missed_cpu_bytes: 'Missed Bytes - CPU Overload',
    missed_bandwidth_bytes: 'Missed Bytes - BW Overload',
    missed_fallback_bytes: 'Missed Bytes - BW Overload',
    missed_filtered_bytes: 'Missed Bytes - Not Multicasted',
    missed_incomplete_bytes: 'Missed Bytes - Frame Lost',
    missed_no_data_bytes: 'Missed Bytes - No Frame',
    missed_no_seg_bytes: 'Missed Bytes - No Segment',
    missed_not_started_bytes: 'Missed Bytes - Unicast Start',
    missed_other_bytes: 'Missed Bytes - Others',
    missed_timeshift_bytes: 'Missed Bytes - Timeshift',
    nb_customer_requests: 'No of requests',
    nb_bytes_received : 'Nb bytes received',
    nb_bytes_sent: 'Nb bytes sent',
    nb_sessions: 'Nb sessions',
    origin_time: 'Get manifest time (Origin)',
    pre_startup_time: 'Pre-startup time',
    progress: 'Completion',
    redirection_time: 'Redirection time',
    resent_packets: 'Nb resent packets',
    reservation_duration: 'Reservation duration',
    retransmitted_received_bytes: 'Retransmitted Bytes',
    session_duration: 'Session duration',
    srm_time:'SRM time',
    start_position:'Start position',
    startup_rating: 'Startup time grade',
    startup_time: 'Startup time',
    total_multicast_bytes: 'Multicast and Retransmitted Bytes',
    total_packets: 'Nb total of packets',
    total_rebuffering_time: 'Total rebuffering time',
    total_stall_time: 'Total freeze time',
    useful_bytes: 'Multicast Bytes',
    vast_time: 'Ad decision response time',
    vx: 'Viewer experience'
  },

  is_ad_skippable: {
    0: 'No',
    1: 'Yes'
  },

  nano_status: {
    0: 'nanoCDN status unknown',
    1: 'nanoCDN not available',
    2: 'nanoCDN detected but not used',
    3: 'nanoCDN used'
  },

  severity: {
    I: 'No error',
    E: 'Error'
  },

  network_used_list: {
    "00":          'Not connected',
    "10":          'Wifi only',
    "20":          'Mobile only',
    "30":          'Ethernet only',
    "90":          'Unknown',
    '00-10':       'Disconnected and Wifi',
    '00-20':       'Disconnected and Mobile',
    '00-30':       'Disconnected and Ethernet',
    '00-90':       'Disconnected and Error',
    '00-10-20':    'Disconnected, Wifi and Mobile',
    '00-10-30':    'Disconnected, Wifi and Ethernet',
    '00-10-90':    'Disconnected, Wifi and Unknown',
    '00-10-20-30': 'Disconnected, Wifi, Mobile and Ethernet',
    '00-20-30':    'Disconnected, Mobile and Ethernet',
    '00-30-90':    'Disconnected, Ethernet and Unknown',
    '10-20':       'Wifi and Mobile',
    '10-30':       'Wifi and Ethernet',
    '10-90':       'Wifi and Unknown',
    '20-30':       'Mobile and Ethernet',
    '20-90':       'Mobile and Unknown',
    '30-90':       'Ethernet and Unknown',
    '10-20-90':    'Wifi, Mobile and Unknown',
    '10-30-90':    'Wifi, Ethernet and Unknown'
  },

  network_subtype_used_list: {
    '0':  'Disconnected',
    '1':  'Unknown',
    '10': 'Wifi unknown',
    '11': 'Wifi 2.4gHz',
    '12': 'Wifi 5gHz',
    '20': 'Mobile unknown',
    '21': '2G',
    '22': '3G',
    '23': '4G',
    '24': '5G',
    '30': 'Ethernet',
    '99': 'Default SmartLib',
    '0-10': 'Disconnected and Wifi unknown',
    '0-11': 'Disconnected and Wifi 2.4gHz',
    '0-12': 'Disconnected and Wifi 5gHz',
    '0-20': 'Disconnected and Mobile unknown',
    '0-21': 'Disconnected and Mobile 2G',
    '0-22': 'Disconnected and Mobile 3G',
    '0-23': 'Disconnected and Mobile 4G',
    '0-24': 'Disconnected and Mobile 5G',
    '0-30': 'Disconnected and Ethernet',
    '0-11-12': 'Disconnected, Wifi 2.4gHz and 5gHz',
    '0-11-30': 'Disconnected, Wifi 2.4gHz and Ethernet',
    '0-12-13': 'Disconnected, Wifi 5gHz and Ethernet',
    '1-11': 'Unknown and Wifi 2.4gHz',
    '1-12': 'Unknown and Wifi 5gHz',
    '1-21': 'Unknown and Mobile 2G',
    '1-22': 'Unknown and Mobile 3G',
    '1-23': 'Unknown and Mobile 4G',
    '1-24': 'Unknown and Mobile 5G',
    '1-30': 'Unknown and Ethernet',
    '1-11-12': 'Unknown, Wifi 2.4gHz and 5gHz',
    '1-11-30': 'Unknown, Wifi 2.4gHz and Ethernet',
    '1-12-13': 'Unknown, Wifi 5gHz and Ethernet',
    '1-10-12-23': 'Unknown, Wifi unknown, Wifi 5gHz and Mobile 4G',
    '10-20': 'Wifi unknown and Mobile unknown',
    '10-21': 'Wifi unknown and Mobile 2G',
    '10-22': 'Wifi unknown and Mobile 3G',
    '10-23': 'Wifi unknown and Mobile 4G',
    '10-24': 'Wifi unknown and Mobile 5G',
    '10-30': 'Wifi unknown and Ethernet',
    '10-99': 'Wifi unknown and Default SmartLib',
    '11-12': 'Wifi 2.4gHz and 5gHz',
    '11-20': 'Wifi 2.4gHz and Mobile unknown',
    '11-21': 'Wifi 2.4gHz and Mobile 2G',
    '11-22': 'Wifi 2.4gHz and Mobile 3G',
    '11-23': 'Wifi 2.4gHz and Mobile 4G',
    '11-24': 'Wifi 2.4gHz and Mobile 5G',
    '11-30': 'Wifi 2.4gHz and Ethernet',
    '11-12-20': 'Wifi 2.4gHz, 5gHz and Mobile unknown',
    '11-12-21': 'Wifi 2.4gHz, 5gHz and Mobile 2G',
    '11-12-22': 'Wifi 2.4gHz, 5gHz and Mobile 3G',
    '11-12-23': 'Wifi 2.4gHz, 5gHz and Mobile 4G',
    '11-12-24': 'Wifi 2.4gHz, 5gHz and Mobile 5G',
    '11-12-30': 'Wifi 2.4gHz, 5gHz and Ethernet',
    '11-21-23': 'Wifi 2.4gHz, Mobile 2G and 4G',
    '11-12-22-23': 'Wifi 2.4gHz, 5gHz, Mobile 3G and 4G',
    '12-20': 'Wifi 5gHz and Mobile unknown',
    '12-21': 'Wifi 5gHz and Mobile 2G',
    '12-22': 'Wifi 5gHz and Mobile 3G',
    '12-23': 'Wifi 5gHz and Mobile 4G',
    '12-24': 'Wifi 5gHz and Mobile 5G',
    '12-30': 'Wifi 5gHz and Ethernet',
    '21-22': 'Mobile 2G and 3G',
    '21-23': 'Mobile 2G and 4G',
    '21-24': 'Mobile 2G and 5G',
    '21-22-23': 'Mobile 2G, 3G and 4G',
    '22-23': 'Mobile 3G and 4G',
    '22-24': 'Mobile 3G and 5G'
  },

  perso_status: {
    1: 'Ok',
    0: 'Ko'
  },

  service_type: {
    1: 'VOD',
    2: 'LIVE'
  },

  status_code: {
    1000: '1000: Invalid content',
    1001: '1001: No server elected',
    1002: '1002: Max session reached',
    1003: '1003: Not allowed cause of license limit',
    1004: '1004: Failed to create session on server',
    1005: '1005: BandwidthManager connection failed',
    1006: '1006: Node (other than edge) is full on Bandwidth manager',
    1007: '1007: Bandwidth manager failed',
    1008: '1008: Free bandwidth failed',
    1009: '1009: Cannot connect to Cable Resource Manager',
    1010: '1010: Cable Resource Manager cannot allocate session on the ERM',
    1011: '1011: Edge node is full on Bandwidth manager',
    1012: '1012: Node is not found on Bandwidth manager',
    1013: '1013: Server reservation failed',
    1020: '1020: Service session quota reached',
    1021: '1021: Provider session quota reached',
    1022: '1022: Reseller session quota reached',
    1023: '1023: Service delivered GB quota reached',
    1024: '1024: Provider delivered GB quota reached',
    1025: '1025: Reseller delivered GB quota reached',
    1026: '1026: Session geo-blocked',
    1027: '1027: service is not found',
    1028: '1028: Authentication invalid token',
    1029: '1029: Authentication token validity expired',
    1030: '1030: Pop streaming priority low quota full on BandwidthManager',
    1031: '1031: Invalid external quota',
    1032: '1032: Global external quota full',
    1033: '1033: Provider external quota full',
    1034: '1034: Terminal id external quota full',
    1035: '1035: Service bandwidth quota reached',
    1036: '1036: Provider bandwidth quota reached',
    1037: '1037: Reseller bandwidth quota reached',
    200: '200: Session OK',
    201: '201: Created',
    202: '202: Accepted',
    203: '203: Non-Authoritative Information',
    204: '204: No Content',
    205: '205: Reset Content',
    206: '206: Partial Content',
    2000: '2000: Edge node not found',
    2001: '2001: Cannot connect to POP node',
    2002: '2002: No POP node found',
    2003: '2003: Cannot connect to Geo-localizer',
    2004: '2004: Content not found',
    2005: '2005: No Cable resource information found on edge node',
    2006: '2006: Unknown error',
    2007: '2007: Invalid range UTC',
    2008: '2008: Cannot create session, POP node is busy',
    2009: '2009: FO Licence not valid',
    2010: '2010: Service id not found in client request',
    2011: '2011: Service invalid signing',
    250: '250: Low on Storage Space',
    300: '300: Multiple Choices',
    301: '301: Moved Permanently',
    302: '302: Moved Temporarily',
    303: '303: See Other',
    304: '304: Not Modified',
    305: '305: Use Proxy',
    3001: '3001: Format not supported',
    3002: '3002: Decoding error',
    3003: '3003: Networking error',
    3004: '3004: Access rights error',
    3005: '3005: Player unspecified error',
    3501: '3501: nanoCDN Cannot find stream',
    3502: '3502: nanoCDN Cannot find track',
    3503: '3503: nanoCDN Max Stream per session reached',
    3504: '3504: nanoCDN Memory Alloc Fail',
    3505: '3505: nanoCDN Max tracks per stream reached',
    3506: '3506: nanoCDN No IP and port Increment',
    3507: '3507: nanoCDN Bad IP or PORT',
    3508: '3508: nanoCDN Too many stream/port for statisticsr',
    3509: '3509: nanoCDN No stream running',
    3510: '3510: nanoCDN Session Time Out',
    3511: '3511: nanoCDN Stop Module',
    400: '400: Bad Request (URL format)',
    401: '401: Unauthorized',
    402: '402: Payment Required',
    403: '403: Forbidden',
    404: '404: Content not found',
    405: '405: Method Not Allowed',
    406: '406: Not Acceptable',
    407: '407: Proxy Authentication Required',
    408: '408: Request timeout',
    409: '409: Conflict',
    410: '410: Gone',
    411: '411: Length Required',
    412: '412: Precondition Failed',
    413: '413: Request Entity Too Large',
    414: '414: Request-URI Too Long',
    415: '415: Unsupported Media Type',
    451: '451: Invalid parameter',
    452: '452: Illegal Conference Identifier',
    453: '453: Not Enough Bandwidth',
    454: '454: Session not found',
    455: '455: Method Not Valid In This State',
    456: '456: Header Field Not Valid',
    457: '457: Invalid Range',
    458: '458: Parameter Is Read-Only',
    459: '459: Aggregate Operation Not Allowed',
    460: '460: Only Aggregate Operation Allowed',
    461: '461: Unsupported Transport',
    462: '462: Unreachable destination',
    500: '500: Internal server error',
    501: '501: Not Implemented',
    502: '502: Bad gateway',
    503: '503: Service Unavailable',
    504: '504: Teardown due to session timeout',
    505: '505: RTSP version not supported',
    551: '551: Option not supported',
    600: '600: Output disconnection',
    601: '601: Timeout disconnection',
    602: '602: Client disconnection',
    603: '603: GES disconnection',
    604: '604: Degraded mode',
    605: '605: Manual disconnection',
    606: '606: Timeout RTCP disconnection',
    607: '607: Teardown without play',
    620: '620: Teardown due to service platform',
    621: '621: Teardown due to session timeout',
    622: '622: Teardown due to session setup timeout',
    623: '623: Teardown due to cluster error',
    30000: '30000: Unknown',
    30001: '30001: No rule match',
    30002: '30002: Other error',
    30003: '30003: Service not found',
    30004: '30004: Geolocalization failed',
    30005: '30005: No CDN available',
    30006: '30006: No redirect URL',
    30007: '30007: Request refused',
    30008: '30008: Request date expired'
  },

  player_error_code: {
    '': 'No Error'
  },

  profil: {
    0: 'Supervisor',
    1: 'Super administrator',
    2: 'Administrator',
    3: 'Advanced supervisor',
  },

  group_type: {
    0: 'Operator',
    1: 'Content provider'
  },

  alert_severity: {
    [-1]: 'IGNORED',
    0: '?',
    1: 'MINOR',
    2: 'MAJOR'
  },

  statistic: {
    'provided_dtype': 'Database type',
    'inferred_dtype': 'Inferred type',
    'memory_usage': 'Sample memory (bytes)',
    'n': 'Sample size',
    'n_distinct': 'Distinct elements',
    'n_unique': 'Unique elements',
    'n_null': 'Null elements',
    'n_distinct_uppercase': 'Distinct uppercase',
    'n_distinct_strip': 'Distinct stripped',
    'n_non_ascii': 'Non-ascii elements',
    'mean': 'Mean',
    'min': 'Minimum',
    'max': 'Maximum',
    'n_zero': 'Zero elements',
    'n_neg': 'Negative elements',
    'std': 'Std. deviation',
    'kurtosis': 'Kurtosis',
    'skewness': 'Skewness',
    'mad': 'Median Absolute Deviation',
    'n_outliers': 'Outliers'
  }

}
