import { legacyTranslate } from './display_association'

const en = {
  legacy: legacyTranslate,
  actions: {
    logout:      'Logout',
    isSigninOut: 'Signin out...',
    signin:      'Sign in',
    apply:       'Apply'
  },
  app: {
    branding:  'This is streaming at its peak',
    copyright: 'Copyright 2023 Broadpeak',
    filters:   'Filters'
  },
  button: {
    apply: "Apply",
    confirm: "Confirm",
    cancel: "Cancel",
    reset: "Reset"
  },
  errorsSyntax:{
    no_email : "Providing an email is mandatory.",
    not_an_email : "Next email is not an email ",
    no_name : "Providing a name is mandatory."
  },
  errors: {
    invalid_login_params: 'Invalid login or password',
    login_failed: 'Invalid login or password',
    invalid_pwd_last_attempt:
      'Password is wrong. One more attempt before locking this account.',
    account_pwd_expired: 'Your password has expired, please change it now',
    account_pwd_forgotten: 'You forgot your password, please change it now',
    pwd_token_expired: 'Your token is expired, please change it now',
    pwd_wrong_token: 'Your token is not in good format, please change it now',
    account_was_locked:
      'Your account got too many failed login attempts, it has been locked',
    account_locked: 'Your account is locked',
    logout_failed: 'Failed removing session',
    session_timeout: 'Your session expired. Please sign in',
    invalid_license: 'License is invalid, please check',
    connections_quota_reached: 'Too many users are currently connected',
    db_failure: 'Internal connection error',
    unable_to_connect_database: 'Internal connection error',
    unable_to_connect_zookeeper: 'Internal connection error',
    invalid_email: 'Incorrect email',
    error_noData_with_filters: 'Selected filters not applicable',
    error_one_value_in_dashboard: 'Only one filter is applicable',
    error_Clickhouse: 'Pb with clickhouse',
    invalid_email_info: 'A password reset link was sent to :',
    login_locked: 'Account is locked',
    invalid_admin_session: 'Current session has not enought right',
    unique_violation: 'Already exists',
    no_data: 'No data',
  },
  errorsEncrypter:{
    no_data: 'No data to encypt/decrypt',
    no_key_found: 'Key for encryption/decryption is not defined',
    too_long_key: 'Key for encryption/decryption is too long',
    encrypt_failed: 'Encryption failed',
    decrypt_failed: 'Decryption failed'
  },
  errorsReporting:{
    no_data: 'No data',
    unique_violation: 'Reporting is already set'
  },
  errorsGroups:{
    no_data: 'No data',
    users_exist_in_group: 'Group can not be removed, some users are still in this group.',
    unique_violation: 'Group already exists'
  },
  errorsDiagnostic:{
    no_date: 'No date',
    bad_format_table: 'Table name not match with a-z, A-Z, 0-9 and "_"',
    bad_format_name: 'Diagnostics name not match with a-z, A-Z, 0-9, "_", "-" and "."',
    invalid_filters: 'Filters set not have dimension or value not match with a-z, A-Z, 0-9, "_", "-" and "."',
    unique_violation: 'Diagnostic already exists'
  },
  ranking: {
    totalSessions: 'Total sessions',
    sessionDurations: 'Session duration (s)',
    completion: 'Completion (%)',
    adServerTime: 'Ad server time',
    frequency: 'Frequency',
    duration: 'Duration',
    startupTime: 'Startup time (ms)',
    avgNoFreeze: 'Avg. no. freezes',
    avgNoLayerFreeze: 'Avg. no. of layer switches',
    avgSatisfaction: 'Avg. satisfaction',
    numberOfError: 'Number of errors',
    totalRecords: 'Total records %',
    retransRate: 'Retrans. rate',
    retransVolume: 'Retrans. volume',
    skipRate: 'Skip. rate',
    adExp: 'Ad exp',
    errorsCount: 'Errors count %'
    //kb: 'KB %'
  },
  rankingCustomDahsboard: {
      ucdn_session: 'Session Requests',
      ucdn_succeed_redirect: 'Succeed Requests',
      ucdn_failed_redirect: 'Failed Requests',
      ucdn_request_with_no_rules: 'Request with no rules',
      ucdn_number_rules_checked: 'Number Rules checked',
      ucdn_unique_visitor: 'Unique Visitor',
      ucdn_unique_content: 'Unique Content',

      cdn_sessions: 'Processed Sessions',
      cdn_error_sessions: 'Error Sessions',
      cdn_asset_duration: 'Asset Duration (s)',
      cdn_delivered_volume: 'Delivered Volume (GB)',
      cdn_mean_bitrate: 'Bitrate',
      cdn_session_duration: 'Session Duration (s)',
      cdn_response_time: 'Response Time (ms)',
      cdn_progress: 'Completion %',
      cdn_concurrent_sessions: 'Concurrent Sessions',
      cdn_concurrent_bitrate: 'Concurrent Bitrate (Gb/s)',
      cdn_none: 'None',

      nano_sessions: 'Processed Sessions',
      nano_concurrent_sessions: 'Concurrent Sessions',
      nano_concurrent_bitrate: 'Concurrent Bitrate (Gb/s)',
      nano_retransmission_rate: 'Retransmission Rate %',
      nano_retransmission_overhead: 'Retransmission Overhead (GB)',
      nano_multicast_unicast_ratio: 'Multicast/unicast Ratio %',

      pl_sessions: 'Processed Sessions',
      pl_error_sessions: 'Error Sessions',
      pl_session_duration: 'Session Duration (s)',
      pl_delivered_volume: 'Delivered Volume (GB)',
      pl_unique_visitor: 'Unique Users',
      pl_unique_content: 'Unique Contents',
      pl_startup_time: 'Startup Time (ms)',
      pl_cnt_stall: 'Stall Count (ms)',
      pl_total_stall_duration: 'Stall Duration (ms)',
      pl_cnt_layer_switch: 'Layer Switch',
      pl_vx: 'Viewer Experience',
      pl_redirection_time: 'Redirection Time (ms)',
      pl_stall_rate: 'Stall Rate (stall duration/session duration) %',
      pl_session_no_image: 'Session Exit Before Video Start',
      pl_concurrent_sessions: 'Concurrent Sessions',
      pl_concurrent_bitrate: 'Concurrent Bitrate (Gb/s)',
      pl_none: 'None',

      ad_ad_request: 'Ads Request',
      ad_ad_success: 'Ads Success',
      ad_ad_error: 'Ads Error',
      ad_skippable_ad: 'Skippable Ads',
      ad_skipped_ad: 'Skipped Ads',
      ad_ad_decision_reponse_time: 'Decision Time (ms)',
      ad_skip_rate: 'Skip Rate %',
      ad_ad_vx: 'Ad Viewer Experience',
      ad_ad_server_time: 'Ad server Time (ms)',
      ad_origin_time: 'Origin Time (ms)',
      ad_ad_mean_bitrate: 'Bitrate (kb/s)',
      ad_ad_stalls_count: 'Stall Count',
      ad_ad_stalls_duration: 'Stall Duration (s)',
      ad_ad_layer_switches_number: 'Layer Switch',
      ad_ad_played_time: 'Played Time (s)',
      ad_ad_completion: 'Completion %',
      ad_ad_none: 'None'
  },

  period: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    custom: 'Custom',
    predefined: 'Predefined periods:',
    from: 'From:',
    to: 'To:'
  },

  miscellaneous: {
    unknown: 'Unknown',
    infoForgottenPwd: 'A password reset link was sent to : ',
    empty: 'Unknown',
    exclude_switch: 'Click to go in exclude filter mode'
  },

  settings: {
    gui_expiry_timeout_sec: "Expiry gui timeout (in seconds)",
    pwd_expiry_timeout: "Expiry password timeout (in days)",
    authent_mode: "Authentication mode"
  }
}

export default en
